.dataTables_processing {
    background: rgba(255,255,255,0.8);
    top: 0;
    bottom: 0;
    height: auto;
    z-index: 1;
}
.dataTables_processing .spinner {
    top: 50%;
    transform: translateY(-50%);
}
.cursor-pointer {
    cursor: pointer;
}
.forlog-logo img {
    max-width: 200px;
}
.navbar-brand img {
    height: 1.5rem;
}
